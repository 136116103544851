<template>
  <div class="trustDetail">
    <Icon type="ios-redo"
          class="goback"
          @click="back" />
    <h1>{{activeDetail.cpmc}}</h1>
    <p class="shorter">简称：{{activeDetail.cpjc}}</p>
    <div class="titleBox">
      <div class="item">
        <div class="title-blod">{{activeDetail.cpztmc}}</div>
        <div>产品状态</div>
      </div>
      <div class="item">
        <div class="title-blod">{{activeDetail.cpfl}}</div>
        <div>产品分类</div>
      </div>
      <div class="item">
        <div class="title-blod">{{activeDetail.dwbz}}</div>
        <div>单位/币种</div>
      </div>
      <div class="item">
        <div class="title-blod">{{activeDetail.cplx}}</div>
        <div>产品类型</div>
      </div>
      <div class="item">
        <div class="title-blod">{{activeDetail.yqnhsylmc}}</div>
        <div>预期年化收益率</div>
      </div>
      <div class="item">
        <div class="title-blod">{{activeDetail.syfpfs}}</div>
        <div>收益分配方式</div>
      </div>
      <div class="item">
        <div class="title-blod">{{activeDetail.cpqx}}</div>
        <div>产品期限</div>
      </div>
      <div class="item">
        <div class="title-blod">{{activeDetail.qxlx}}</div>
        <div>期限类型</div>
      </div>
    </div>
    <div>
      <div class="table-title">产品明细信息表</div>
      <cTableItem :titleArr="['融资方','隶属企业']"
                  :contentArr="[activeDetail.rzf,activeDetail.lsqy]"></cTableItem>
      <cTableItem :titleArr="['发行规模','成立规模']"
                  :contentArr="[activeDetail.fxgm,activeDetail.clgm]"></cTableItem>
      <cTableItem :titleArr="['发行机构','发行机构简称']"
                  :contentArr="[activeDetail.fxjg,activeDetail.fxjgjc]"></cTableItem>
      <cTableItem :titleArr="['发行日期','成立时间']"
                  :contentArr="[activeDetail.fxrq,activeDetail.clsj]"></cTableItem>
      <cTableItem :titleArr="['投资门槛','最低追加金额']"
                  :contentArr="[activeDetail.tzmk,activeDetail.zdzjje]"></cTableItem>
      <cTableItem :titleArr="['投资方向','投资方式']"
                  :contentArr="[activeDetail.tzfx,activeDetail.tzfs]"></cTableItem>
      <cTableItem :titleArr="['发行地','项目所在地']"
                  :contentArr="[activeDetail.fxd,activeDetail.xmszdmc]"></cTableItem>
      <cTableItem :titleArr="['收益类型','资产管理人']"
                  :contentArr="[activeDetail.sylx,activeDetail.zcglr]"></cTableItem>
      <cTableItem :titleArr="['托管银行','合作机构']"
                  :contentArr="[activeDetail.tgyh,activeDetail.hzjg]"></cTableItem>
      <cTableItem :titleArr="['募集账户']"
                  type="one"
                  :contentArr="[activeDetail.mjzh]"></cTableItem>
    </div>
    <!-- <div class="tabBox">
      <Tabs value="name1"
            style="height: 74px;">
        <TabPane label="收益详情说明"
                 name="name1"></TabPane>
        <TabPane label="产品特点"
                 name="name2"></TabPane>
        <TabPane label="其他说明"
                 name="name3"></TabPane>
        <TabPane label="增信措施"
                 name="name4"></TabPane>
        <TabPane label="资金用途"
                 name="name5"></TabPane>
        <TabPane label="还款来源"
                 name="name6"></TabPane>
      </Tabs>
    </div> -->
    <!-- <div class="tabContent"
         style="margin-bottom: 20px;">
      <div class="tabText">xxx</div>
      <div class="tabImgBox"><img><img></div>
      <div class="tabText">xxx</div>
    </div> -->
    <!-- <div class="title">相关公告</div>
    <div class="noticeBox">
      <div class="arrow"></div>
      <div class="sign">发行公告</div>
      <div class="content">一场2.5万亿的误会！消费税法征求意见稿来了 白酒要加税？NO！</div>
      <div class="time">2019-09-20</div>
    </div>
    <div class="noticeBox">
      <div class="arrow"></div>
      <div class="sign">发行公告</div>
      <div class="content">一场2.5万亿的误会！消费税法征求意见稿来了 白酒要加税？NO！</div>
      <div class="time">2019-09-20</div>
    </div>
    <div class="title">产品文档</div>
    <div class="noticeBox">
      <div class="arrow"></div>
      <div class="sign">发行公告</div>
      <div class="content">一场2.5万亿的误会！消费税法征求意见稿来了 白酒要加税？NO！</div>
      <div class="time">2019-09-20</div>
    </div>
    <div class="noticeBox">
      <div class="arrow"></div>
      <div class="sign">发行公告</div>
      <div class="content">
        <Tooltip :content="activeDetail.ggbt"
                 max-width="1200"
                 style="width:100%"
                 word-wrap
                            theme="light"
                 placement="top">一场2.5万亿的误会！消费税法征求意见稿来了 白酒要加税？NO！
        </Tooltip>
      </div>
      <div class="time">2019-09-20</div>
    </div> -->
    <div style="margin-top:20px">
      <div class="table-title">项目介绍</div>
      <cTableItem :titleArr="['项目名称','项目区位']"
                  :contentArr="[activeDetail.xmmc,activeDetail.xmqw]"></cTableItem>
      <cTableItem :titleArr="['建设内容及规划','项目总投资']"
                  :contentArr="[activeDetail.jsnrjgh,activeDetail.xmztz]"></cTableItem>
      <cTableItem :titleArr="['自有资金投入比例','项目建设规划']"
                  :contentArr="[activeDetail.zyzjtrbl,activeDetail.xmjsgh]"></cTableItem>
      <cTableItem :titleArr="['建设期限','项目证照批复']"
                  :contentArr="[activeDetail.jsqx,activeDetail.xmzzpf]"></cTableItem>
    </div>
    <div style="margin-top: 20px;">
      <div class="table-title">其他说明</div>
      <cTableItem :titleArr="['融资方','项目公司']"
                  :contentArr="[activeDetail.rzf,activeDetail.xmgs]"></cTableItem>
      <cTableItem :titleArr="['担保方','股权质押标的公司']"
                  :contentArr="[activeDetail.dbf,activeDetail.gqzybdgs]"></cTableItem>
      <cTableItem :titleArr="['抵押物']"
                  type="one"
                  :contentArr="[activeDetail.dyw]"></cTableItem>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import cTableItem from '@/components/cTableItem';
export default {
  name: 'trustDetail',
  data () {
    return {
      isMore: false,
      tab: 'name1'
    }
  },
  computed: {
    ...mapState([
      'activeDetail'
    ])
  },
  components: {
    cTableItem
  },
  mounted () {
  },
  methods: {
    getMore () {
      this.isMore = true;
    },

    back () {
      this.$router.go(-1);
    },
  },
  filters: {
    statusF (value) {
      switch (value) {
        case 1:
          return '在售';
        case 2:
          return '售罄';
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.trustDetail {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
  position: relative;
  .goback {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #00b6ff;
    font-size: 36px;
    transform: rotateY(180deg);
    cursor: pointer;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    color: rgba(21, 98, 214, 1);
    margin: 20px 0;
    border-left: 4px solid #1562d6;
    height: 18px;
    line-height: 18px;
    padding-left: 10px;
  }
  h1 {
    font-size: 20px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 26px;
  }
  .shorter {
    font-size: 14px;
    color: rgba(153, 153, 153, 1);
    margin-bottom: 20px;
  }
  div.titleBox {
    width: 1250px;
    height: 113px;
    background: rgba(250, 250, 253, 1);
    border-radius: 5px;
    border: 1px solid rgba(227, 227, 229, 1);
    padding: 30px 0;
    font-size: 14px;
    color: rgba(102, 102, 102, 1);
    display: flex;
    margin-bottom: 10px;
    .item {
      border-right: 1px solid #e3e3e5;
      display: flex;
      flex-direction: column;
      height: 53px;
      width: 160px;
      .title-blod {
        font-size: 18px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        flex: 1;
      }
    }
    .item:nth-last-of-type(1) {
      border: none;
    }
  }
  .tabBox {
    width: 100%;
    height: 48px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 10px 20px 0px rgba(102, 102, 102, 0.1);
    border: 1px solid rgba(214, 215, 217, 1);
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
  }
  .tabContent {
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    line-height: 21px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .tabImgBox {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      img {
        height: 200px;
        width: 300px;
      }
    }
  }
  div {
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    div.table-title {
      @include flex(flex-start);
      padding-left: 20px;
      background: rgba(231, 240, 252, 1);
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      border: 1px solid rgba(214, 215, 217, 1);
    }
    div {
      @include flex;
      height: 40px;
      line-height: 40px;
      .line-title:nth-of-type(1) {
        border-left: 1px solid rgba(214, 215, 217, 1);
      }
      .line-title {
        background: rgba(250, 250, 253, 1);
        width: 414px;
        border-right: 1px solid rgba(214, 215, 217, 1);
        border-bottom: 1px solid rgba(214, 215, 217, 1);
      }
      .line-content {
        background: #fff;
        width: 414px;
        border-right: 1px solid rgba(214, 215, 217, 1);
        border-bottom: 1px solid rgba(214, 215, 217, 1);
      }
    }
  }
  .noticeBox {
    border-bottom: 1px dashed rgba(214, 215, 217, 1);
    height: 44px;
    width: 100%;
    line-height: 44px;
    @include flex(flex-start);
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: rgba(51, 51, 51, 1);
    .arrow {
      width: 0px;
      height: 0px;
      border-left: 8px solid #aaa;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid transparent;
    }
    .sign {
      width: 60px;
      height: 24px;
      background: rgba(246, 250, 255, 1);
      border-radius: 2px;
      border: 1px solid rgba(21, 98, 214, 1);
      color: #1562d6;
      line-height: 24px;
      white-space: nowrap;
      margin-right: 10px;
    }
    .content {
      flex: 2;
      justify-content: flex-start;
      overflow: hidden;
      white-space: nowrap;
    }
    .time {
      font-size: 14px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
